import { useQuery } from 'core/use-query'
import { useNavigate, useParams } from 'react-router'
import { getCookie } from './cookies'
import ContentPage from 'components/dataroom/content-page'
import SmallLoader from 'components/shared/small-loader'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import toast from 'core/utils/toast'
import type { Provider } from './types'
import { post } from 'core/services/http-service'

type ProviderReference = Pick<Provider, 'reference'>

function Callback() {
    const { t } = useTranslation()
    const { provider } = useParams()
    const params = useQuery()
    const navigate = useNavigate()

    async function setToken() {
        const code = params.get('code')
        const state = params.get('state')
        const cookieState = getCookie(`${provider}_oauth_state`)
        const redirect = getCookie(`${provider}_oauth_redirect`)

        if (!cookieState || cookieState !== state) {
            toast('error', t('web_me_options_linked_mailbox_error'))
            navigate(redirect ?? '/me/options')

            return
        }
        const postData = {
            provider,
            code,
        }

        const {
            data: { success, email },
        } = await post<typeof postData, { data: { success: boolean; email: string } }>('/v1/web/oauth/token', postData)

        if (success) {
            toast('success', t('web_me_options_linked_mailbox_success', { email }))
        } else {
            toast('error', t('web_me_options_linked_mailbox_error'))
        }

        navigate(redirect ?? '/me/options')
    }

    useEffect(() => {
        setToken()
    }, [])

    return (
        <ContentPage>
            <div className="flex items-center flex-col gap-2">
                <SmallLoader />
                <p className="mb-8 text-center text-sm text-regent-gray">
                    {t('web_me_options_linked_mailbox_loading')}
                </p>
            </div>
        </ContentPage>
    )
}

export default Callback
